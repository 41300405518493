<template>
	<div>
		<v-row class="mx-2">
			<v-col cols="12">
				<v-card flat style="border-radius: 12px" elevation="6">
					<v-card-title>
						<v-row>
							<v-col class="d-flex align-center justify-start" cols="8">
								<h5 class="d-flex align-left">{{ $t("hospital") }} {{ $t("admins") }}</h5>
							</v-col>
							<v-col cols="4">
								<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								:label="$t('search-staff')"
								single-line
								hide-details
								filled
								dense
								background-color="#E8E8E8"
								rounded
								class="table-search-button"
								/>
							</v-col>
						</v-row>
					</v-card-title>
					<v-data-table
						:loading="loading"
						dense
						:headers="headers"
						:headers-length="headers.length"
						:search="search"
						:no-data-text="$t('no-data-available')"
						:loading-text="$t('loading')"
						:items="users"
						:items-per-page="-1"
						sort-by="id"
						item-key="id"
						fixed-header
						style="cursor: pointer; overflow-y: auto;"
						:height="testHeight"
						:footer-props="{
							itemsPerPageText: this.$t('row'),
							itemsPerPageAllText: this.$t('all'),
						}"
					>
						<template v-slot:item="{ item }">
							<tr @click="expand(item)">
								<td class="table-cols">
									<img :src="patient_icon(item.status)" height="24px" />
								</td>
								<td class="table-cols">
									{{ item.firstName }} {{ item.lastName }}
								</td>
								<td class="table-cols">
									{{ item.hospitalAssignedData === null ? "" : item.hospitalAssignedData.hospitalName }}
								</td>

								<td class="table-cols">
									{{ item.email }}
								</td>
								<td class="table-cols">
									{{ item.phone }}
								</td>
								<!-- <td class="table-cols">
									{{ item.address }}
								</td> -->
								<td class="table-cols-right">
									<v-chip v-if="item.status !== 1" label @click.stop="sendActivationEmail(item)" pill small color="primary" class="ma-2 table-deassign-button">
										{{ $t('send-activation-email') }}
									</v-chip>
                  <v-chip v-else label @click.stop="sendActivationEmail(item)" pill small color="primary" class="ma-2 table-deassign-button">
                    {{ $t('send-reset-password-email') }}
                  </v-chip>
								</td>
							</tr>
						</template>
						<template v-slot:[`footer.prepend`]>
							<v-btn class="table-create-button" label="Test" @click=" dialog = true; dialogtype = 'add';">
								<!-- <v-icon small class="icon">mdi-pencil-plus-outline</v-icon> -->
								<img class="pt-1" height="20px" width="20px" :src="patients_white" style="margin-right:3%; margin-bottom: 5%;"/>
								<span style="text-transform: none;">{{ $t("addNewAdminTooltip") }}</span>
							</v-btn>
						</template>
					</v-data-table>
				</v-card>
				<AddHospitalAdminDialog
				v-if="dialog"
				:type="dialogtype"
				:dialog="dialog"
				:selectedItem="selectedItem"
				@false="closeDialog"
				@updated="updatedAdmin"
				/>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import AddHospitalAdminDialog from '@/views/dialogs/HospitalAdminDialog.vue';
import { patientSecondary, patientBlack, patientsWhite } from '@/assets';
export default {
	components: { AddHospitalAdminDialog },
	data () {
		return {
			search: '',
			loading: true,
			dialog: false,
			users: [],
			headerClass: 'black_inner--text !important',
			dialogtype: null,
			selectedItem: {},
			patient_secondary: patientSecondary,
			patient_black: patientBlack,
			patients_white: patientsWhite,
		};
	},
	computed: {
		...mapGetters({ roleName: 'authentication/getRole', delay: 'authentication/getDelay' }),
		...mapState({
			usersFromRepo: (state) => state.hospitals.hospitalAdmins,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		height () {
			return window.innerHeight - 250;
		},
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 320;
			case 'sm':
				return window.innerHeight - 300;
			case 'md':
				return window.innerHeight - 300;
			case 'lg':
				return window.innerHeight - 280;
			case 'xl':
				return window.innerHeight - 280;
			default:
				return 0;
			}
		},
		headers () {
			var headers = [];
			headers = [
				{
					text: this.$t('header-status'),
					value: 'statusShortDescription',
					class: this.headerClass,
				},
				{
					text: this.$t('header-full-name'),
					value: 'fullname',
					class: this.headerClass,
				},
				{
					text: this.$t('header-hospital-name'),
					value: 'hospitalAssignedData?.hospitalName',
					class: this.headerClass,
				},

				{
					text: this.$t('header-email'),
					value: 'email',
					class: this.headerClass,
				},
				{
					text: this.$t('header-phone'),
					value: 'phone',
					class: this.headerClass,
				},
				// {
				// 	text: this.$t('header-address'),
				// 	value: 'gender',
				// 	class: this.headerClass,
				// },
				{
					text: '',
					value: '',
					class: this.headerClass,
				},
			];
			return headers;
		},
	},
	methods: {
		patient_icon (status) {
			switch (status) {
			case 1:
				return this.patient_black;
			default:
				return this.patient_secondary;
			}
		},
		async expand (item) {
			this.dialogtype = 'edit';
			this.selectedItem = item;
			this.dialog = true;
		},
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
		async getUsers () {
			await this.$store.dispatch('hospitals/getHospitalAdmins').then(() => {
				this.users = this.usersFromRepo;
				this.loading = false;
			});
		},
		async getVerifiers () {
			const header = {
				domainValue: 'USER_STATUS',
			};
			await this.$store.dispatch('verifiers/getVerifiersForDomain', header);
		},
		adminDetails (item) {
			this.$router.push({
				name: 'Admin Details',
				params: { id: `${item.id}` },
			});
		},
		async updatedAdmin (res) {
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'addedAdmin',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'errorAddingAdmin',
					type: 'error',
					color: 'main_red',
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
			this.dialog = false;
			this.selectedItem = {};
			this.dialogtype = null;
			await this.getVerifiers();
			await this.getUsers();
		},
		async closeDialog () {
			this.dialog = false;
			this.selectedItem = {};
			this.dialogtype = null;
			await this.getVerifiers();
			await this.getUsers();
		},
		async sendActivationEmail (item) {
			const sendValidationEmail = { email: item.email };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
						this.$store.commit('alerts/add', {
							id: 'addedAdmin',
							type: 'success',
							color: 'main_green',
							// message: res.msg,
							message: this.$t('success'),
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					} else {
						this.$store.commit('alerts/add', {
							id: 'errorAddingAdmin',
							type: 'error',
							color: 'main_red',
							// message: res.msg,
							message: this.$t('failed'),
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					}
				});
		},
	},
	async mounted () {
		await this.getVerifiers();
		await this.getUsers();
	},
};
</script>
