<template>
  <v-dialog
    v-model="dialog"
    width="800"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-form v-model="valid">
		<v-card flat style="overflow: hidden">
			<v-card-title class="secondary py-1" style="height: 40px">
				<span class="dialog-title">
					{{ type === "add" ? $t("create") : $t("edit") }}
					{{ $t("hospital") }} {{ $t("admin") }}
				</span>
				<v-spacer />
				<v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
			</v-card-title>
			<v-card-text color="primary" class="mt-5">
				<span class="d-flex align-end justify-end px-3"><span style="color: red">{{ $t("requiredFields") }}</span></span>
			</v-card-text>
			<v-row class="px-6 pt-3 px-8" dense>
				<v-col cols="12">
					<v-row class="pl-3 pb-4">
						<span style="font-size: 18px">{{$t('personal')}} {{$t('information')}}</span>
					</v-row>
					<v-row dense class="py-2">
						<v-col class="pt-1" cols="12" lg="6" md="6" sm="12">
							<v-text-field
							v-model="selectedUser.firstName"
              :label="$t('name')"
							prepend-inner-icon="mdi-account mr-1"
							dense
							background-color="#E8E8E8"
							rounded
							outlined
							style="border-radius: 12px !important; height: 40px"
							:rules="[(rule) => !!rule || '']"
							></v-text-field>
						</v-col>
						<v-col class="pt-1" cols="12" lg="6" md="6" sm="12">
							<v-text-field
							v-model="selectedUser.lastName"
              :label="$t('surname')"
							prepend-inner-icon="mdi-account mr-1"
							dense
							background-color="#E8E8E8"
							rounded
							outlined
							style="border-radius: 12px !important; height: 40px"
							:rules="[(rule) => !!rule || '']"
							></v-text-field>
						</v-col>
					</v-row>
					<v-row dense class="pb-2">
						<v-col class="pt-1 pb-2" cols="12" lg="6" md="6" sm="12">
							<validation-provider rules="required|email" :name="$t('email')" v-slot="{ errors }">
								<v-text-field
									v-model="selectedUser.email"
                  :label="$t('email')"
									prepend-inner-icon="mdi-email mr-1"
									dense
									background-color="#E8E8E8"
									rounded
									outlined
									style="border-radius: 12px !important; height: 40px"
									:disabled="type !== 'add' ? true : false"
									:error-messages="(emailError = errors)"
								></v-text-field>
							</validation-provider>
						</v-col>
						<v-col class="pt-1" cols="12" lg="6" md="6" sm="12">
							<validation-provider rules="required" v-slot="{ errors }">
								<v-text-field
									ref="phoneNumber"
									v-model="selectedUser.phone"
									validate-on-blur
                  :label="$t('phone-number')"
									prepend-inner-icon="mdi-cellphone mr-4"
									dense
									background-color="#E8E8E8"
									rounded
									outlined
									style="border-radius: 12px !important; height: 40px"
									:error-messages="errors"
								></v-text-field>
							</validation-provider>
						</v-col>
					</v-row>
					<v-row v-if="type === 'edit'" dense>
						<v-row class="pa-4 pt-8">
							<span style="font-size: 18px">{{$t('status')}}</span>
						</v-row>
						<v-col cols="12" lg="12" md="12" sm="12">
							<v-select
								v-model="selectedUser.status"
                :label="$t('set-status')"
								prepend-inner-icon="mdi-hospital-building mr-1"
								:items="statuses"
								item-value="id"
								item-text="name"
								dense
								background-color="#E8E8E8"
								rounded
								outlined
								style="border-radius: 12px !important; height: 40px"
							>
							</v-select>
						</v-col>
					</v-row>
					<v-row dense>
						<v-row class="pa-4 pt-8">
							<span style="font-size: 18px">{{$t('hospital')}}</span>
						</v-row>
						<v-col cols="12" lg="12" md="12" sm="12">
							<v-select
								v-model="selectedUser.hospitalAssignedData.id"
                :label="$t('select-office')"
								prepend-inner-icon="mdi-hospital-building mr-1"
								:items="hospitals"
								item-value="id"
								item-text="hospitalName"
								dense
								:disabled="type !== 'add'"
								background-color="#E8E8E8"
								rounded
								outlined
								style="border-radius: 12px !important; height: 40px"
								:rules="[(rule) => !!rule || '']"
							>
							</v-select>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-card-actions>
				<AlertDialog class="ml-6" v-if="alertType" :title="title" :type="alertType"></AlertDialog>
				<v-spacer></v-spacer>
				<div class="my-6 mx-5">
					<v-btn class="table-cancel-button" @click="$emit('false')">
						<span color="black--text">{{ $t("cancel") }}</span>
					</v-btn>
					<v-btn
						class="table-create-button mx-2"
						:loading="loading"
						:disabled="loading || !valid"
						@click="loader = 'loading'"
					>
						<span>{{ $t("save") }}</span>
					</v-btn>
				</div>
			</v-card-actions>
		</v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import AlertDialog from '@/views/Client/components/AlertDialog.vue';
// import BirthdatePicker from "@/views/Client/components/BirthdatePicker";
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';

export default {
	props: ['dialog', 'type', 'selectedItem'],
	components: {
		AlertDialog,
		// BirthdatePicker,
	},
	data: () => ({
		loader: null,
		loading: false,
		title: 'Something went wrong',
		alertType: false,
		snackColor: '',
		statuses: [
			{ id: 1, name: 'Active' },
			{ id: 0, name: 'Inactive' },
		],
		userStatus: null,
		object: {
			addressLatitude: null,
			address: null,
			addressLongitude: null,
			birthdate: null,
			birthdateAsString: null,
			birthdateWithoutTime: null,
			birthdateWithTime: null,
			city: null,
			country: null,
			countryName: null,
			doctorMetadataReadDto: null,
			email: null,
			firstName: null,
			gender: null,
			hospitalAssignedData: {
				id: null,
			},
			id: null,
			lastName: null,
			medicalTeamsData: null,
			nurseMetadata: null,
			patientMetadata: null,
			phone: null,
			roleData: null,
			roleId: null,
			state: null,
			status: null,
			statusLongDescription: null,
			statusShortDescription: null,
			userSettingsJson: null,
			userTimezone: null,
			username: null,
		},
		address_info: {
			address: '',
			postal_code: '',
			city: '',
			state: '',
			country: '',
			lat: 42.001071,
			lng: 20.957554,
		},
		birthdate: '2002-01-01',
		hospitals: [],
		valid: false,
		addressToValidate: null,
		formattedAddress: null,
		timezone: null,
		emailError: false,
		showErrors: false,
		validationErrors: [],
		phoneError: false,
	}),
	computed: {
		...mapState({
			hospitalsFromRepo: (state) => state.hospitals.hospitals,
			timeZones: (state) => state.verifiers.timeZones,
		}),
		selectedUser () {
			if (this.type === 'edit') {
				return this.selectedItem;
			} else return this.object;
		},
		hospitalAddress () {
			return this.hospitals.find(
				(element) => element.id === this.selectedUser.hospitalAssignedData.id,
			);
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.createStaff()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		'selectedUser.phone': function (val) {
			if (!isValidPhoneNumber(val)) {
				this.phoneError = true;
			} else {
				this.phoneError = false;
			}
			this.selectedUser.phone = new AsYouType().input(val);
		},
	},
	async mounted () {
		await this.getHospitals();
		if (this.type === 'edit') {
			this.addressData();
			this.userStatus = this.selectedUser.status;
			if (!isValidPhoneNumber(this.selectedUser.phone)) {
				this.phoneError = true;
			}
		}
	},
	methods: {
		async addressData () {
			this.address_info = {
				address: this.selectedUser.address,
				postal_code: this.selectedUser.zip,
				city: this.selectedUser.city,
				state: this.selectedUser.state,
				country: this.selectedUser.country,
				lat: this.selectedUser.addressLatitude,
				lng: this.selectedUser.addressLongitude,
			};
		},
		async timeZone (lat, lng) {
			const object = {
				lat: lat,
				lng: lng,
			};
			const timeZone = await this.$store.dispatch('googleAPI/getTimeZone', object);
			return timeZone;
		},
		// async locationDetails () {
		// 	if (this.selectedUser.address) {
		// 		const geoLocation = await this.$store.dispatch(
		// 			'googleAPI/getGeoLocation',
		// 			this.selectedUser.address,
		// 		);
		// 		const addressComponents = geoLocation.address_components;
		// 		this.address_info.lat = geoLocation.geometry.location.lat;
		// 		this.address_info.lng = geoLocation.geometry.location.lng;
		// 		this.formattedAddress = geoLocation.formatted_address;
		// 		this.address_info.address = this.formattedAddress;
		// 		addressComponents.forEach((ac) => {
		// 			if (ac.types.includes('administrative_area_level_1')) {
		// 				this.address_info.state = ac.long_name;
		// 			}
		// 			if (ac.types.includes('locality')) {
		// 				this.address_info.city = ac.long_name;
		// 			}
		// 			if (ac.types.includes('country')) {
		// 				this.address_info.country = ac.long_name;
		// 			}
		// 			if (ac.types.includes('postal_code')) {
		// 				this.address_info.postal_code = ac.long_name;
		// 			}
		// 		});
		// 		if (this.address_info.lat !== null) {
		// 			const tz = await this.timeZone(
		// 				this.address_info.lat,
		// 				this.address_info.lng,
		// 			);
		// 			if (tz) {
		// 				this.selectedUser.userTimezone = tz.zoneName;
		// 			}
		// 		}
		// 	}
		// },
		async locationDetails () {
			if (this.selectedUser.address) {
				this.waiting = true;
				const geoLocation = await this.$store.dispatch(
					'googleAPI/getGeoLocation',
					this.selectedUser.address,
				);
				this.waiting = false;
				if (!geoLocation) {
					this.validationErrors.push('address');
					this.address_info = {
						address: '',
						postal_code: '',
						city: '',
						state: '',
						country: '',
						lat: 0,
						lng: 0,
					};
					return;
				}
				const addressComponents = geoLocation.address;
				this.address_info.lat = geoLocation.lat;
				this.address_info.lng = geoLocation.lon;
				this.formattedAddress = geoLocation.display_name;
				this.address_info.address = this.formattedAddress;
				this.address_info.city = addressComponents.city;
				this.address_info.state = addressComponents.state;
				this.address_info.country = addressComponents.country;
				this.address_info.postal_code = addressComponents.postcode;
				if (this.address_info.lat !== null) {
					const tz = await this.timeZone(
						this.address_info.lat,
						this.address_info.lng,
					);
					if (tz) {
						this.timezone = tz.zoneName;
					}
				}
			}
		},
		checkValid () {
			this.validationErrors = [];
			if (this.phoneError) {
				this.validationErrors.push('phone');
			}
			if (this.emailError.length > 0) {
				this.validationErrors.push('email');
			}
			// if (this.address_info.address === "") {
			//   this.validationErrors.push("address");
			// }
			if (this.validationErrors.length > 0) {
				return false;
			} else return true;
		},
		async createStaff () {
			const valid = this.checkValid();
			if (!valid) {
				this.title = 'Provided data is not valid: ' + this.validationErrors.toString();
				this.alertType = 'error';
				this.showErrors = true;
			}
			if (valid) {
				this.validationErrors = [];
				this.loading = true;
				this.showErrors = false;
				const timezone = await this.timeZone(
					this.hospitalAddress.addressLatitude,
					this.hospitalAddress.addressLongitude,
				);
				const userSettings = {
					Units: 'IMPERIAL',
					Concentration: 'mg/dl',
				};
				const body = {
					firstName: this.selectedUser.firstName,
					lastName: this.selectedUser.lastName,
					phone: this.selectedUser.phone.split(' ').join(''),
					email: this.selectedUser.email,
					address: this.hospitalAddress.address,
					zip: this.hospitalAddress.zip,
					city: this.hospitalAddress.city,
					state: this.hospitalAddress.state,
					country: this.hospitalAddress.country,
					addressLatitude: this.hospitalAddress.addressLatitude,
					addressLongitude: this.hospitalAddress.addressLongitude,
					username: this.selectedUser.email,
					password: '123456789',
					roleId: 6,
					hospitalId: this.selectedUser.hospitalAssignedData.id,
					userTimezone: timezone !== null ? timezone?.zoneName : '',
					userSettingsJson: JSON.stringify(userSettings),
				};
				switch (this.type) {
				case 'add':
					this.createuser(body);
					break;
				case 'edit':
					this.updateuser(body);
					break;
				default:
					break;
				}
			}
		},
		async updateuser (body) {
			body.id = this.selectedUser.id;
			const sendValidationEmail = { email: this.selectedUser.email };
			await this.$store
				.dispatch('users/updateUser', body)
				.then(async (res) => {
					if (this.userStatus === 0 && this.selectedUser.status === 1) {
						await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail);
					}
					this.$emit('updated', res);
				})
				.catch(() => {
					this.alertType = 'error';
					this.title = this.$t('adminCouldNotUpdate');
					this.showErrors = true;
				});
		},
		async createuser (body) {
			await this.$store.dispatch('users/createUser', body).then(async (res) => {
				if (res.resFlag) {
					this.loading = false;
					this.$emit('updated', res);
				}
			});
		},
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
				if (this.hospitalsFromRepo !== null) {
					this.hospitals = this.hospitalsFromRepo;
				} else this.hospitals = [];
			});
		},
	},
};
</script>
